<template>
  <div id="app" class="app">
    <div class="dashboard_main w-full flex mb-4">
      <dashboard-card :number="GET_STATISTICS_MAIN_PAGE.count_clients" :text="$t('client_s')" icon="usergroup-add" class="w-1/3 mr-4 " style=""/>
      <dashboard-card :number="GET_STATISTICS_MAIN_PAGE.total_requests" :text="$t('request_s')" icon="plus-square" class="w-1/3 mr-4" :bg-color="'#438655'" color="white"/>
      <dashboard-card :number="GET_STATISTICS_MAIN_PAGE.completed_requests" icon="check-circle" class="w-1/3">
        <template slot="title">
          <div style="line-height: initial;">
            <div>
              {{$t('finish_s')}}
            </div>
            <div>
              {{$t('request_s').toLowerCase()}}
            </div>
          </div>
        </template>
      </dashboard-card>
    </div>
    <card>
      <AgGridTable
          @open_drawer="open_drawer_request"
          :column_defs="column_defs_create_request"
          :end_point="'organizations/customer/'"
          name_event_bus="customer_request_api_event_bus"/>
    </card>
    <client-and-request ref="refClientAndRequest" :title="$t('add_request_text')"/>
    <RequestShowDrawer ref="refRequestShowDrawer"/>
  </div>
</template>

<script>
import DashboardCard from "@/pages/MainPage/DashboardCard/DashboardCard";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import Card from "@/crm_components/card/Card";
import ClientAndRequest from "@/pages/AddClient/ClientAndRequest/ClientAndRequest";
import RequestShowDrawer from "@/pages/AddClient/Request/RequestShowDrawer";
import ColumnDefsCreateRequest from "@/pages/AddClient/RequestMixins/ColumnDefsCreateRequest";
import OpenDrawerRequestMixin from "@/pages/AddClient/RequestMixins/OpenDrawerRequestMixin";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MainPage",
  components:{
    DashboardCard,
    AgGridTable,
    Card,
    ClientAndRequest,
    RequestShowDrawer
  },
  mixins:[
    ColumnDefsCreateRequest,
    OpenDrawerRequestMixin,
    DeleteMixin,
  ],
  computed:{
    ...mapGetters({
      GET_STATISTICS_MAIN_PAGE:'client/GET_STATISTICS_MAIN_PAGE',
    })
  },
  methods:{
    ...mapActions({
      STATISTICS_MAIN_PAGE:'client/STATISTICS_MAIN_PAGE',
    })
  },
  mounted() {
    this.STATISTICS_MAIN_PAGE()
  }
}
</script>

